<template>
  <div class="LeetCodeVideo">
    <div class="container">
      <img src="@/assets/img/course/Class2/banner1.png" alt="" />
      <!-- <PolyVideo :class-id="2"></PolyVideo> -->
      <img src="@/assets/img/course/Class2/banner2.png" alt="" />
      <img src="@/assets/img/course/Class2/banner3.png" alt="" />
      <img src="@/assets/img/course/Class2/banner4.png" alt="" />
      <img src="@/assets/img/course/Class2/banner5.png" alt="" />
      <img src="@/assets/img/course/Class2/banner6.png" alt="" />
      <img src="@/assets/img/course/Class2/banner7.png" alt="" />
      <img src="@/assets/img/course/Class2/banner8.png" alt="" />
      <img src="@/assets/img/course/Class2/banner9.png" alt="" />
      <img src="@/assets/img/course/Class2/banner10.png" alt="" />
      <img src="@/assets/img/course/Class2/banner11.png" alt="" />
      <img src="@/assets/img/course/Class2/banner12.png" alt="" />
    </div>
    <div class="prices">
      <span class="usdPrice">${{ courseCombosList[0].usdprice }}/</span>
      <span class="rmbPrice">￥{{ courseCombosList[0].price }} </span>
      <div class="buy" @click="buyNow">立即购买</div>
    </div>
  </div>
</template>
<script>
import { getCourseDetail, getMinimumPrice } from "@/service/course";
import PolyVideo from "@/components/PolyVideo";
export default {
  name: "Class2",
  components: { PolyVideo },
  data() {
    return {
      courseCombosList: [],
      miniumPrice: {},
    };
  },
  mounted() {
    getCourseDetail(2).then((res) => {
      this.courseCombosList = res.result.courseCombosList;
    });
    // getMinimumPrice().then((res) => {
    //   if (res.success) {
    //     this.miniumPrice = res.result;
    //   }
    // });
  },
  methods: {
    buyNow() {
      this.$router.push("/subscription");
    },
  },
};
</script>
<style scoped lang="scss">
@import url("../../assets/css/course.scss");
.LeetCodeVideo {
  width: 100%;
  .container {
    img {
      width: 100%;
      vertical-align: top;
    }
  }
}
</style>